import React from "react";
import { Layout } from "../../components";

const SerwisyInternetowe = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Serwisy internetowe",
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/web-development/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Serwisy internetowe</h1>
          </div>
          <div className="single_offer__content">
            <div className="single_offer__services">
              <p className="text-center">
                ergonomia - design - realizacja celów
              </p>
              <p>
                Serwisy internetowe zawsze realizują jakieś cele, dlatego na
                pierwszym miejscu stawiamy na skuteczność i adekwatność.
                Zapewniamy wsparcia w zakresie zaprojektowania i wdrożenia;
                serwisów korporacyjnych, stron typu landing page, sklepów
                internetowych i rozwiązań ecommerce, portali oraz dedykowanych
                aplikacji szytych na miarę.
              </p>
              <p className="margin-sm">
                Na przestrzeni lat wypracowaliśmy proces realizacji, który
                zapewnia sprawną współpracę oraz połączenie ergonomii i dobrego
                designu z realizacją celów biznesowych Klienta.
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <h3>Dlaczego my?</h3>
                  <p className="margin-sm">
                    Doświadczenie Przemyślany proces projektowy Rekomendacja
                    realizującego cele rozwiązania Autorska “Procedura Oddania
                    Projektu”
                  </p>
                  Szerokie kompetencje dodatkowe (Analityka, UX, SEO/SEM,
                  Copywriting) Bezpieczeństwo (ForSecure)
                </div>
                <div className="col-lg-6">
                  <h3>Pełny proces realizacji projektu</h3>
                  <p>
                    W zależności od potrzeb lub charakteru przedsięwzięcia
                    zastosowanie mają wszystkie lub wybrane etapy projektowe.
                    Cały proces realizujemy w modelu Quality Assurance (klarowna
                    komunikacja, zdefiniowane wymagania, role i sposób pracy).
                  </p>
                  <p>
                    <i>“Zaniedbanie przygotowania to przygotowanie porażki.”</i>{" "}
                    <br /> Benjamin Franklin
                  </p>
                </div>
              </div>
              <h3>Briefing</h3>
              <p>
                Wykonujemy wstępna analizę potrzeb, poznajemy biznes, branżę,
                ofertę Klienta oraz jego oczekiwania co do projektu. Omawiamy
                funkcjonalności, wstępna skalę projektu oraz budżet. Wszystko po
                to, aby obie strony oceniły potencjalną wartość współpracy.
              </p>

              <h3>Warsztaty</h3>

              <p>
                Kluczem do sukcesu w realizowaniu projektów internetowych jest
                etap przygotowań do ich realizacji. Na początku, gdy dysponujemy
                jedynie pomysłem lub ogólnymi założeniami, nie jesteśmy w stanie
                określić skali projektu, stopnia jego skomplikowania czy też
                charakteru wszystkich funkcjonalności. Niezwykle istotne jest
                zebranie wymagań, określenie i sprecyzowanie funkcjonalności
                oraz architektury serwisu. Aby uniknąć nieporozumień na etapie
                wdrożenia, kluczowe jest klarowne zdefiniowanie każdej
                oczekiwanej funkcji systemu. Celem przeprowadzenia warsztatów
                jest wypracowanie dokumentacji projektu, która pozwoli na
                zarekomendowanie odpowiedniej technologii.
              </p>
              <h3>Dokumentacja projektu</h3>
              <p>
                Na podstawie danych zebranych podczas briefingu oraz warsztatów
                opracowujemy dokumentację projektu. Dokumentacja ta jest
                wiążącym zbiorem funkcjonalności, w sposób szczegółowy definiuje
                zakres prac i stanowi punkt odniesienie dla weryfikacji
                spełniania założeń przez finalny produkt. Wypracowana
                dokumentacja jest własnością Klienta.
              </p>
              <h3>Makieta/model funkcjonalny</h3>
              <p>
                Na podstawie audytu konkurencji, dobrych praktyk, badań z
                użytkownikami oraz naszych doświadczeń opracowujemy makietę.
                Makieta to szkic serwisu pokazujący jego architekturę,
                rozmieszczenie elementów w serwisie. To model po którym możemy
                się poruszać, przechodzić pomiędzy stronami, zapoznać się ze
                strukturą serwisu przed jego powstaniem. Etap ten jest istotny z
                uwagi na dbałość o wygodę użytkowania serwisu i jego skuteczność
                - to właśnie na tym etapie opracowujemy najlepsze rozwiązania,
                które mają zapewnić realizację celów biznesowych.
              </p>
              <h3>
                Koncepcja kreatywna inspiracje - benchmarki - rekomendacje
                kreatywne
              </h3>
              <p className="margin-sm">
                Gdy posiadamy już pełną wiedzę o funkcjonalnościach serwisu oraz
                jego strukturze możemy rozpocząć prace nad koncepcją graficzną.
                Wykonujemy research w branży Klienta i poza nią. Zbieramy
                inspiracje rekomendując najlepszy w naszej opinii kierunek w
                jakim powinna pójść kreacja.
              </p>
              <p>
                Opracowujemy Style Guide - zestaw elementów składowych serwisu
                (kolorystyka, typografia, buttony etc.).
              </p>
              <h3>Design atrakcyjny - przemyślany - adekwatny - </h3>
              <p>
                Projektujemy kluczowe widoki serwisu, który jest konsultowany z
                Klientem. Po dopracowaniu i akceptacji kluczowego widoku
                projektujemy wszystkie podstrony serwisu zgodnie z opracowaną
                koncepcją.
              </p>
              <h3>Wdrożenie</h3>
              <p className="margin-sm">
                Uważamy, że korzystanie z jednej technologii nie jest dobrym
                rozwiązaniem. Wychodzimy z założenia, że to technologię trzeba
                dobrać i dostosować do wymagań projektu a nie projekt do
                technologii, dlatego też rekomendacja w tym zakresie zawsze ma
                indywidualny charakter i jest możliwa po zdefiniowaniu realnych
                potrzeb i budżetu.
              </p>
              <p>
                W ramach wdrożenia kodujemy warstwę front-end oraz back-end,
                optymalizujemy kod, publikujemy treści dostarczone przez Klienta
                a zwieńczeniem prac jest nasza autorska, 34 etapowa Procedura
                Oddania Projektu (P.O.P). Etap wdrożenia realizujemy z
                uwzględnieniem procesów z zakresu Quality Control (poszukiwanie
                błędów, testowanie aplikacji).
              </p>
              <h3>Uruchomienie usługi</h3>
              <p>
                Przenosimy serwis do środowiska docelowego, gdzie wykonujemy
                testy funkcjonalne i wydajnościowe.
              </p>
              <h3>Obsługa posprzedażowa</h3>
              <p>
                Zapewniamy stałą opiekę w zakresie aktualizacji treści,
                elementów serwisu i bezpieczeństwa. Dbamy o rozwój i rozbudowę
                serwisów o kolejne wymagane funkcjonalności. Posiadamy
                narzędzia, wiedzę i kompetencje do tego abyś czuł się
                bezpiecznie, komfortowo i odzyskał czas.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default SerwisyInternetowe;
